<template>
  <div class="main-login">
    <div class="view">
      <!-- 二维码 -->
      <div class="content-view">
        <div class="login-title">登录</div>
        <div id="qrcode" ref="qrcode"></div>
        <div class="saoma">微信扫码</div>
        <div class="gongzh">关注公众号并注册登录</div>
      </div>
      <!-- logo/按钮 -->
      <div class="logo">
        <div>
          <img src="../assets/login/weix.png" alt="">
        </div>
        <div class="loginBtn" :style="{backgroundColor:isSkin}" @click="loginMethod()">账号登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import {Message} from 'element-ui'
import QRCode from 'qrcodejs2'
export default {
  data () {
    return {
      task:''
    }
  },
  created(){
    this.$store.commit('changClick',1)
    var skin = sessionStorage.getItem('Skin')
    var zskin = sessionStorage.getItem('ZSkin')
    if (skin) {
        this.$store.commit('changSkin',skin)
    }
    if (zskin) {
        this.$store.commit('changZSkin',zskin)
    }
    this.getQrCode()
  },
  computed:{
    isSkin(){
		  return this.$store.state.isSkin
    }
  },
  methods:{
    /**获取二维码**/ 
    getQrCode(){
      var that = this
      that.$api.getQrCode().then(res=>{
        if (res.data.code == 0) {
          that.$nextTick(() => {
            if (that.$refs.qrcode != undefined) {
              that.$refs.qrcode.innerHTML = "";
            }
            let qrcode = new QRCode('qrcode',{
              width: 152, 
              height: 152, 
              text: res.data.data.url
            })
          })
          that.task = setInterval(function () {
            that.checkLogin(res.data.data.ticket)
          },3000)
        }
      })
    },
    /**验证是否关注公众号**/ 
    checkLogin(key){
      var that = this
      that.$api.checkLogin({
        ticket:key
      }).then(res=>{
        console.log(res);
        if (res.data.code == 0) {
          clearInterval(that.task)
          that.getWXLogin(res.data.data)
        }
      })
    },
    /**微信授权登陆**/ 
    getWXLogin(key){
      var that = this
      that.$api.getWXLogin({
        openId:key
      }).then(res=>{
        if (res.data.code == 0) {
          sessionStorage.setItem('token',res.data.data.token)
          sessionStorage.setItem('userHeadImg',res.data.data.userInfo.headImgUrl)
          sessionStorage.setItem('userName',res.data.data.userInfo.userName)
          sessionStorage.setItem('userNickName',res.data.data.userInfo.nickname)
          sessionStorage.setItem('Teachers',res.data.data.userInfo.identity)
          sessionStorage.setItem('bindWechat',res.data.data.userInfo.bindWechat)
          Message({
            showClose: true,
            message: '登录成功！',
            type: 'success'
          })
          setTimeout(()=>{
            this.$router.push('/');
            this.$store.commit('changClick',0)
            this.$store.commit('changLogin',1)
            this.$store.commit('changNickname',res.data.data.userInfo.nickname)
            this.$store.commit('changHeadImgUrl',res.data.data.userInfo.headImgUrl)
            this.$store.commit('changTeachers',res.data.data.userInfo.identity)
          },2000)
        }else if (res.data.code == 101) {
          this.$router.push({
            path:'/bindMobile',
            query:{
              openID:key,
            }
          });
          this.$store.commit('changClick',1)
        }
      })
    },
    loginMethod(){
      this.$router.push('/accountLogin');
      this.$store.commit('changClick',1)
    }
  },
  destroyed(){
    clearInterval(this.task)
  }
}
</script>

<style scoped lang="scss">
.main-login{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .view{
    width: 427px;
    height: 442px;
    background: #FCFCFC;
    margin-top: 50px;
    margin-bottom: 175px;
    .content-view{
      width: 152px;
      margin-top: 50px;
      margin-left: 137px;
      .login-title{
        width: 100%;
        font-size: 24px;
        line-height: 34px;
        color: #333333;
        text-align: center;
      }
      #qrcode{
        width: 152px;
        height: 152px;
        margin-top: 20px;
      }
      .saoma{
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        text-align: center;
        margin-top: 10px;
      }
      .gongzh{
        font-size: 12px;
        line-height: 17px;
        color: #666666;
        text-align: center;
        margin-top: 10px;
      }
    }
    .logo{
      display: flex;
      justify-items: center;
      align-items: center;
      margin-left: 10px;
      margin-top: 15px;
      .loginBtn{
        width: 144px;
        height: 42px;
        border-radius: 4px;
        line-height: 42px;
        text-align: center;
        font-size: 16px;
        color: #FFF;
        margin-left: 2px;
        cursor: pointer;
      }
    }
  }
}
</style>